import React from 'react'
import { Button, Spinner, Card, Form, Alert, } from 'react-bootstrap';
import succes from  "../assets/succes.png"

export default function ActivationSuccessPage() {
  return (
    <div style={{display: "flex", padding: "50px", alignItems: "center",justifyContent: "center"}}> 
         <Card bg='light' style={{ width: '25rem', alignItems: 'center', justifyContent: 'center' }}
          className="mb-2">
            
         <Card.Title>
         <Card.Img variant="top"
         style={{width: '50px', marginTop: '20px'}}
          src={succes} />
         </Card.Title>
      <Card.Body style={{ width: '25rem', alignItems: 'center', justifyContent: 'center' }}>
        <h3 style={{textAlign: "center"}}>Account activated successfully.</h3>
      </Card.Body>
      
    </Card>
    </div>

  )
}
