import React, { useState, useEffect } from "react";
import axios from "axios";
import { RechargeCardSalesData } from "../data/sample-data";

export default function Home() {
  const [totalSales, setTotalSales] = useState(0);
  const [names, setNames] = useState([]);
  let people = [];
  let result = 0;

  const handleGetData = async () => {
    return await axios.get('http://192.168.0.2:3000/').then((response) => {
        people = response.data.data;
        setNames(
          people.map((item) => {
            return item
          })
        )
        //alert(people)
    }).catch((err) => console.log(err.response))
  };

  useEffect(() => {
      handleGetData();

    return () => {};
  }, [names]);

  return (
    <>
      <div>
      <header>
          <div className="App-header">
            <h1>AutoPay </h1>
          </div>
        </header>
      </div>
      <body>
        <div className="body-part1">
          <h1>The home of automatic payments</h1>
        </div>
         <footer>
          <div></div>
        </footer>
      </body>
    </>
  );
}
